import { Link } from "react-router-dom";
import { useWindowSize } from "../../hooks/useWindowDimensions";
import LogoIcon from "../../assets/img/logo.png";
import logo_respensive from "../../assets/img/logo-resp.svg";
import logoSvg from "../../assets/img/logoSvg.svg";

const Logo = ({ isSidebarClosed, isLogin }) => {
  const isSmallScreen = useWindowSize() <= 900;
  return (
    <Link className={isLogin ? "logo-login" : "logo"}>
      <img
        alt="logo"
        src={
          isLogin
            ? logoSvg
            : isSmallScreen || isSidebarClosed
            ? logo_respensive
            : LogoIcon
        }
      />
    </Link>
  );
};

export default Logo;
