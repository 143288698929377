import React, { createContext, useEffect, useReducer, useMemo } from "react";
import jwtDecode from "jwt-decode";
import SplashScreen from "../components/SplashScreen";
import axios from "../utilities/axios";
import LoadingScreen from "../components/LoadingScreen";

const initialAuthState = {
  isAuthenticated: false,
  isVerified: false,
  isInitialised: false,
  user: null,
  isLogout: false,
};

export const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const setSession = (token) => {
  if (token) {
    localStorage.setItem("token", token);
    axios.defaults.withCredentials = true;

    localStorage.setItem("token_type", "Bearer");
  } else {
    localStorage.removeItem("token");
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user, isVerified } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isVerified,
        isInitialised: true,
        isLogout: false,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isVerified: user.isVerified,
        isLogout: false,
        user,
      };
    }
    case "REFRESHTOKEN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isVerified: user.isVerified,
        isLogout: false,
        user,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        isVerified: false,
        isLogout: false,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isLogout: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password, rememberMe) => {
    const response = await axios.post("/auth/login", {
      email,
      password,
      stayConnected: rememberMe || false,
    });
    const {
      data: {
        data: { accessToken },
      },
    } = await axios.get("/auth/tknr");

    const { user } = response.data.data;

    setSession(accessToken);
    dispatch({
      type: "LOGIN",
      payload: {
        user,
        isVerified: user.isVerified,
        isAuthenticated: true,
      },
    });
    return (window.location = "/project");
  };

  const register = async (values) => {
    const response = await axios.post(`/auth/register`, values);
    const {
      data: {
        data: { accessToken },
      },
    } = await axios.get("/auth/tknr");
    const { user } = response.data.data;

    setSession(accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
        isVerified: false,
        isAuthenticated: true,
      },
    });
    window.location = "/verify-email";
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };
  useEffect(() => {
    const initialise = async () => {
      try {
        const token = window.localStorage.getItem("token");
        setSession(token);
        if (token) {
          if (isValidToken(token)) {
            setSession(token);

            const response = await axios.get("/api/users/me", {
              withCredentials: true,
            });
            const {
              data: {
                data: { accessToken },
              },
            } = await axios.get("/auth/tknr");

            setSession(accessToken);
            const user = response?.data?.data;

            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: true,
                isVerified: user.isVerified,
                user,
              },
            });

            const hasLogged = localStorage.getItem("hasLogged");

            if (!hasLogged) {
              localStorage.setItem("hasLogged", "true");
            }
          } else {
            dispatch({
              type: "INITIALISE",
              payload: {
                isAuthenticated: false,
                user: null,
              },
            });
            localStorage.setItem("hasLogged", "false");
          }
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
          localStorage.setItem("hasLogged", "false");
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);
  const authContextValue = useMemo(
    () => ({
      ...state,
      method: "JWT",
      login,
      logout,
      register,
    }),
    [state, login, logout, register]
  );

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
