import axios from "axios";
import { isValidToken } from "../contexts/JWTAuthContext";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_URL,
});

const allowedUrls = [
  "/auth/login",
  "/auth/register",
  "/auth/tknr",
  "/auth/password-reset/request",
  "/auth/password-reset",
];

axiosInstance.interceptors.request.use(async (request) => {
  if (allowedUrls.includes(request.url)) return request;

  const token = localStorage.getItem("token");
  if (token && isValidToken(token)) {
    return request;
  }

  try {
    const {
      data: {
        data: { accessToken },
      },
    } = await axiosInstance.get("/auth/tknr");
    localStorage.setItem("token", accessToken);
    request.headers.Authorization = `${localStorage.getItem(
      "token_type"
    )} ${accessToken}`;
    return request;
  } catch (_) {
    localStorage.removeItem("token");
    window.location.href = "/login";
  }
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      if (window.location.pathname === "/verify-email") {
        return Promise.reject(
          (error.response && error.response.data) || "une erreur est survenue"
        );
      }
    } else {
      return Promise.reject(
        (error.response && error.response.data) || "une erreur est survenue"
      );
    }
  }
);

export default axiosInstance;
