import React from "react";
import { Modal } from "antd";
import AddWritingForm from "./AddWritingForm";

const ModalComponent = ({
  id,
  open,
  headerContent,
  handleClose,
  data,
  content,
  ...rest
}) => {
  return (
    <>
      <Modal
        destroyOnClose
        title="Nouvelle écriture"
        open={open}
        onCancel={() => handleClose(id)}
        centred
        height={1000}
        className="add-writing-modal"
        footer={false}
        closable={false}
      >
        <AddWritingForm
          onCancel={() => handleClose(id)}
          edit={data?.edit}
          element={data?.element}
          selectedCategory={data?.selectedCategory}
          projectId={data?.projectId}
          isAction={data?.isAction}
          date={data?.date}
          type={data?.type}
        />
      </Modal>
    </>
  );
};

export default ModalComponent;
