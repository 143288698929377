import React from "react";

const IconMode = ({ topText, bottomText, icon, classNameParent, classNameContainer, classNameIcon, classNameText, ...rest }) => {
  return (
    <div className={classNameParent}>
       {
          topText && <span className={classNameText}>{topText}</span>
        }
        <div className={classNameContainer}>
        <img className={classNameIcon} src={icon}/> 
        </div> 
        {
          bottomText && <span className={classNameText}>{bottomText}</span>
        }

        </div>
  ) 
};

export default IconMode;
