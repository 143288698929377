import { DatePicker } from "antd";
import locale from "antd/lib/date-picker/locale/fr_FR";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import rangePicker from "../../assets/img/rangePicker.png";
import { formatMonthRange } from "../../utilities/filters";
import dayjs from "dayjs";
import { ReactComponent as NextIcon } from "../../assets/img/icons/next.svg";
import { ReactComponent as PrevIcon } from "../../assets/img/icons/prev.svg";

const CustomRangePicker = ({
  className,
  selectedMonthRange,
  handleMonthChange,
  isSyntheseSelected,
  label,
}) => {
  const customCellRender = (value) => {
    const formattedDate = dayjs(value)
      .format("MMM")
      .replace(/\./g, "")
      .slice(0, 3);

    return <div className="ant-picker-cell-inner">{formattedDate}</div>;
  };

  return (
    <DatePicker.RangePicker
      defaultValue={selectedMonthRange}
      onChange={handleMonthChange}
      format={"M"}
      separator={null}
      locale={locale}
      cellRender={customCellRender}
      picker="month"
      superNextIcon={<NextIcon />}
      superPrevIcon={<PrevIcon />}
      dropdownClassName="custom-RangePicker-dropdown"
      allowClear={false}
      className={className}
      suffixIcon={
        isSyntheseSelected ? (
          <span id="input-label-synthese">
            {formatMonthRange(selectedMonthRange)}
          </span>
        ) : (
          <div className="range-picker-button-wrapper">
            <img src={rangePicker} alt="Range Picker Icon" id="range-id" />
            <span className="input-label-range-picker">{label}</span>
          </div>
        )
      }
    />
  );
};

export default CustomRangePicker;
