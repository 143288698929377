import { getColumnNameFromMonths, groupMonths } from "./regroupment";

export const ROW_HEIGHT = 65;
export const HEADER_HEIGHT = 120;
export const LISTING_HEIGHT = 72;

function getCellProps(
  record,
  identifier,
  title,
  month,
  isLastColumn,
  rowIndex
) {
  const prefix = record.isEmpty
    ? "identifier=empty"
    : identifier === "category" && record.name
    ? `category=${record?.id}&parent_id=${record.parentId}&isSpecial=${record?.isSpecial}&identifier=${identifier}&levelId=${record?.levelId}&index=${record?.index}`
    : identifier === "category" && !record.name
    ? `category=${record?.category?.id}&parent_id=${record?.category?.parentId}&isSpecial=${record?.category?.isSpecial}&identifier=${identifier}&levelId=${record?.category?.levelId}&dataIndex=${month}&index=${record?.category?.index}`
    : identifier === "element" && record.name
    ? `category=${record?.id}&isSpecial=${record?.isSpecial}&identifier=${identifier}&levelId=${record?.levelId}&dataIndex=${month}`
    : `category=${record?.category?.id}&isSpecial=${record?.category?.isSpecial}&identifier=${identifier}&levelId=${record?.category?.levelId}&dataIndex=${month}&specialId=${record?.id}&elementId=${record?.[month]?.id}`;

  return {
    dragPrefix: prefix,
    categoryName: record?.name,
    title: title,
    type: identifier,
    isLastColumn: isLastColumn,
    category: record,
    rowIndex,
  };
}

function getTableColumns(
  renderCell,
  mode,
  monthsGrouped,
  renderHeader,
  synthesis,
  objectif,
  isListing,
  RegroupmentValue
) {
  const commonCellProps = (col) => {
    return {
      onHeaderCell: () => ({
        style: {
          borderWidth: "0.25px",
          borderStyle: "solid",
          borderColor: "#EBEBEB",
          padding: "0",
          background: "#F9F9F9",
          fixed: "left",
        },
      }),
      render: (value, record) => {
        return renderCell(
          getCellProps(record, record?.isEmpty ? "empty" : col.cellType, mode)
        );
      },

      onCell: (record) => ({
        record,
        dataIndex: col.cellType === "name" ? "category" : col.cellType,
        type: col.cellType === "name" ? "category" : col.cellType,
        style: {
          borderWidth: "0.25px",
          borderStyle: "solid",
          borderColor: "#EBEBEB",
          borderCollapse: "collapse",
          background:
            record.isEmpty && col.cellType === "objective"
              ? "white"
              : "#F9F9F9",
          zIndex: 11,
        },
      }),
      fixed: "left",
      width: "290px",
      className: "create-categories",
    };
  };

  const columns = [
    { cellName: "name", cellType: "category" },
    ...(objectif && !isListing
      ? [{ cellName: "objective", cellType: "objective" }]
      : []),
    ...(synthesis && !isListing
      ? [{ cellName: "synthese", cellType: "synthese" }]
      : []),
  ].map((col) => ({
    dataIndex: col.cellName,
    key: col.cellName,
    title: renderHeader({ type: col.cellName }),
    ...commonCellProps(col),
  }));

  const dateColumns = monthsGrouped.map((months, index, array) => ({
    title: getColumnNameFromMonths(months, RegroupmentValue),
    dataIndex: months.join(", "),
    key: index,
    width: "290px",
    className: "th-treso-date",
    render: (value, record, rowIndex) => {
      return renderCell(
        getCellProps(
          record,
          record?.isEmpty ? "empty" : "element",
          mode,
          months.join(", "),
          index === array.length - 1 ||
            (index === array.length - 2 && monthsGrouped.length > 2),
          rowIndex
        )
      );
    },
    onCell: (record) => {
      return {
        dataIndex: months.join(", "),
        record,

        type: "element",
        style: {
          borderWidth: "0.25px",
          borderStyle: "solid",
          width: "290px",
          borderColor: "#EBEBEB",
          borderCollapse: "collapse",
          backgroundColor: "white",
        },
      };
    },
    onHeaderCell: (column) => {
      return {
        style: {
          background: mode === "treso" ? "#FFFA7D" : "#aaefe6",
          borderWidth: "0.25px",
          borderStyle: "solid",
          borderColor: "#EBEBEB",
          textAlign: "center",
          fontSize: "1.8rem",
          padding: "2.5rem",
          width: "256px",
          textTransform: "capitalize",
          borderRadius: column.key === 0 ? "3.7rem 0 0 3.7rem" : "",
        },
      };
    },
  }));

  return [...columns, ...dateColumns];
}

const generateEmptyRow = (key, renderCell) => ({
  key,
  name: "",
  isEmpty: true,
  render: (value, record) => renderCell(getCellProps(record, "empty", "")),
});

function findMaxDepth(obj) {
  if (typeof obj !== "object" || obj === null) {
    return 0;
  }

  let maxDepth = 0;

  for (const key in obj) {
    if (maxDepth < obj[key].length) {
      maxDepth = obj[key].length;
    }
  }

  return maxDepth;
}

function addIdKeyToCategories(categories) {
  return categories.map((category) => {
    const newCategory = { ...category, key: category.id };
    if (category.children) {
      newCategory.children = addIdKeyToCategories(category.children);
    }
    return newCategory;
  });
}

const calculateMonthTotals = (categories, rangesArray) => {
  const calculateTotal = (category, months = {}) => {
    for (const monthsRange of rangesArray) {
      const rangeKey = monthsRange.join(", ");

      for (const month of monthsRange) {
        if (category && category.elements && category.elements[month]) {
          if (!months[month]) {
            months[month] = 0;
          }
          for (const item of category.elements[month]) {
            months[month] += item.amount;
            if (month !== rangeKey) {
              if (!months[rangeKey]) {
                months[rangeKey] = 0;
              }
              months[rangeKey] += item.amount;
            }
          }
        }
      }
    }

    if (category.children && category.children.length > 0) {
      for (const subCategory of category.children) {
        calculateTotal(subCategory, months);
      }

      category.children = calculateMonthTotals(category.children, rangesArray);
    }

    return { ...category, ...months };
  };

  return categories.map((category) => calculateTotal(category));
};

function truncateWithThreeDots(inputString, maxLength) {
  return inputString?.length > maxLength
    ? inputString.slice(0, maxLength) + "..."
    : inputString;
}

const generateClassName = (status) => {
  switch (status) {
    case "pointé":
      return "pointed-class";
    case "engagé":
      return "engaged-class";
    case "prévisionnel":
      return "prev-class";
    case "simulation":
      return "simul-class";
    default:
      return "default-class";
  }
};

const generateClassNameCompta = (status, type) => {
  if (type) {
    return "compta-sold-class";
  }
  switch (status) {
    case "réel":
      return "real-class";
    case "simulation":
      return "simul-class";
      return "default-class";
  }
};

function calculateBalanceSums(array) {
  return array?.reduce(
    (acc, curr) => {
      const synthese = parseFloat(curr.synthese);

      if (synthese > 0) {
        acc.positiveBalance += synthese;
      } else {
        acc.negativeBalance -= synthese;
      }

      acc.finalBalance = acc.positiveBalance - acc.negativeBalance;
      return acc;
    },
    {
      positiveBalance: 0,
      negativeBalance: 0,
      finalBalance: 0,
    }
  );
}

const findById = (id, items) => {
  for (const item of items) {
    if (item.id == id) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const found = findById(id, item.children);
      if (found) {
        return found;
      }
    }
  }
  return null;
};
const findIndexById = (id, items) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      return i;
    }
  }
  return -1;
};

const findNextItemSameLevel = (id, items) => {
  const parent = items.find(
    (item) => item.children && findIndexById(id, item.children) !== -1
  );
  if (parent) {
    const index = findIndexById(id, parent.children);
    if (index !== -1 && index + 1 < parent.children.length) {
      return parent.children[index + 1];
    }
  }
  return null;
};

const updateSynthesis = (arr1, arr2) => {
  if (!arr2?.elementsSynthese) return;
  return arr1.map((item) => {
    const matchingSynthesis = arr2?.elementsSynthese?.find(
      (sItem) => sItem.categoryId === item.id
    );
    if (matchingSynthesis) {
      item.synthese = matchingSynthesis.synthese;
    }
    if (!matchingSynthesis) {
      item.synthese = 0;
    }
    if (item.children && item.children.length > 0) {
      item.children = updateSynthesis(item.children, arr2);
      item.synthese += item.children.reduce(
        (sum, child) => sum + child.synthese,
        0
      );
    }
    return item;
  });
};

function getMaxDepth(groupedElements, category) {
  let maxDepth = 0;
  groupedElements.forEach((range) => {
    let currentDepth = 0;
    range.forEach((month) => {
      const monthData = category?.[month];
      if (monthData) {
        currentDepth = currentDepth + monthData.length;
      }
    });

    if (currentDepth > maxDepth) {
      maxDepth = currentDepth;
    }
  });

  return maxDepth;
}

function getElementsPerRange(groupedElements, categories) {
  const elementsPerRange = {};

  groupedElements.forEach((range) => {
    const elements = [];
    range.forEach((month) => {
      const monthData = categories[month];
      if (monthData) {
        elements.push(...monthData);
      }
    });

    if (elements.length > 0) {
      const rangeKey = range.join(", ");
      elementsPerRange[rangeKey] = elements;
    }
  });

  return elementsPerRange;
}

function sortByAmount(objToSort, sortType) {
  const sortedObj = {};

  for (const key in objToSort) {
    if (Object.prototype.hasOwnProperty.call(objToSort, key)) {
      const innerArray = objToSort[key];

      const sortedArray = innerArray.slice().sort((a, b) => {
        if (sortType === "asc") {
          if (a.amount === b.amount) {
            return new Date(a.createdAt) - new Date(b.createdAt);
          } else {
            return a.amount - b.amount;
          }
        } else if (sortType === "desc") {
          if (a.amount === b.amount) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          } else {
            return b.amount - a.amount;
          }
        }
      });

      sortedObj[key] = sortedArray;
    }
  }

  return sortedObj;
}

function getElementsPerRangeElements(ranges, dataObject, sortValue) {
  const result = {};
  ranges.forEach((range) => {
    const elements = [];
    range.forEach((month) => {
      const monthData = dataObject[month];
      if (monthData) {
        elements.push(...monthData);
      }
    });

    if (elements.length > 0) {
      const rangeKey = range.join(", ");
      result[rangeKey] = elements;
    }
  });

  return sortByAmount(result, sortValue);
}

function createArrayOfObjects(elementsPerRange) {
  const ranges = Object.values(elementsPerRange);
  const maxLength = Math.max(...ranges.map((arr) => arr.length));

  const resultArray = [];

  for (let i = 0; i < maxLength; i++) {
    const objectWithElements = {};

    Object.entries(elementsPerRange).forEach(([range, elements]) => {
      if (elements[i] !== undefined) {
        objectWithElements[range] = elements[i];
      }
    });

    resultArray.push(objectWithElements);
  }

  return resultArray;
}

const calculateFooterSum = (currentElement, mode) => {
  const initialValue = {
    positiveBalance: 0,
    negativeBalance: 0,
    finalBalance: 0,
  };

  const totals = currentElement.reduce((accumulator, element) => {
    accumulator.positiveBalance += parseFloat(element.positiveBalance);
    accumulator.negativeBalance += parseFloat(element.negativeBalance);

    if (mode === "compta") {
      accumulator.finalBalance += parseFloat(element.finalBalance);
    } else {
      accumulator.finalBalance = parseFloat(element.finalBalance);
    }

    return accumulator;
  }, initialValue);

  return totals;
};

export {
  getTableColumns,
  updateSynthesis,
  addIdKeyToCategories,
  truncateWithThreeDots,
  generateEmptyRow,
  findMaxDepth,
  getMaxDepth,
  calculateMonthTotals,
  generateClassNameCompta,
  generateClassName,
  calculateBalanceSums,
  getElementsPerRangeElements,
  findById,
  getElementsPerRange,
  createArrayOfObjects,
  calculateFooterSum,
  findNextItemSameLevel,
};
