import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SIDEBAR_LIST } from "../../data/constants";
import {
  handelEndMonth,
  handelStartMonth,
  handleSelectedMonths,
  handleToggleSidebar,
} from "../../data/slices/layout";
import { generateMonthList, parseDate } from "../../utilities/filters";
import { Header, Toggle } from "./components/Header";
import NavButton from "./components/NavButton";
import Settings from "./components/Settings";
import { handleActiveSynthesis } from "./../../data/slices/layout";
import { openListObjectiveDialog } from "../../data/slices/objectives";
import { useLocation } from "react-router-dom";
import { setMonthList } from "../../data/slices/categories";

const Sidebar = () => {
  const dispatch = useDispatch();
  const { mode, objectif, synthesis, isSidebarClosed, selectedMonths } =
    useSelector((state) => state.layout);

  const [mainItems, setMainItems] = useState(SIDEBAR_LIST);
  const [showOverlay, setShowOverlay] = useState(false);
  const [activeSubItem, setActiveSubItem] = useState("");
  const location = useLocation();
  const currentURL = location?.pathname;
  const isListing = currentURL.includes("listing");

  const handleItemClick = (item) => {
    if (location.pathname.includes("listing")) return;
    //to be refactored
    if (item === "Afficher la synthèse" && !isListing) {
      dispatch(handleActiveSynthesis(!synthesis));
    }
    if (item === "Afficher un budget prev.") {
      dispatch(openListObjectiveDialog({ status: "true" }));
    }
  };

  const handleClickToggleSidebar = () => {
    dispatch(handleToggleSidebar(!isSidebarClosed));
  };

  const handleOpenOverlay = () => {
    setShowOverlay(true);
  };

  const handleMonthChange = useCallback(
    (dates) => {
      if (dates && dates?.length === 2) {
        dispatch(handelStartMonth(dates[0]));
        dispatch(handelEndMonth(dates[1]));
        dispatch(handleSelectedMonths(generateMonthList(dates[0], dates[1])));
        dispatch(setMonthList(generateMonthList(dates[0], dates[1])));
      }
    },
    [dispatch]
  );
  const start = selectedMonths?.map((month) => parseDate(month))[0];

  const end = selectedMonths?.map((month) => parseDate(month))[
    selectedMonths?.length - 1
  ];
  return (
    <div className="sidebar">
      <div className={`sidebar-wrapper ${isSidebarClosed && "sidebar-closed"}`}>
        <div
          className={`sidebar ${mode === "compta" ? "blue" : " yellow"} ${
            isSidebarClosed && "sidebar-closed"
          }`}
        >
          <Header
            isSidebarClosed={isSidebarClosed}
            className="sidebar_header"
          />
          {mainItems?.map((item, i) => {
            const isGraphPage = currentURL.includes("graph");

            const itemsToHideOnGraphPage = [0, 3, 4];

            const shouldDisplayItem =
              !isGraphPage || !itemsToHideOnGraphPage.includes(item?.id);

            return shouldDisplayItem ? (
              <>
                <NavButton
                  mode={mode}
                  handleMonthChange={handleMonthChange}
                  selectedMonthRange={[start, end]}
                  openOverlay={handleOpenOverlay}
                  onClick={handleItemClick}
                  name={activeSubItem === item.name ? activeSubItem : item.name}
                  icon={item.icon}
                  label={item.label}
                  arrowIcon={item.arrowIcon}
                  isActive={activeSubItem === item.name}
                  hasDivider={item.hasDivider}
                  isDatePicker={item.isDatePicker}
                  isNavButton={item.navButton}
                  isSwitch={item?.isSwitch}
                  background={item.background}
                  route={item.route}
                  key={item?.id}
                  subItems={item?.items}
                  isPopover={item?.isPopover}
                  disabled={
                    item.name === "Afficher la synthèse"
                      ? synthesis === true
                        ? true
                        : false
                      : item.name === "Afficher un budget prev."
                      ? objectif === true
                        ? true
                        : false
                      : false
                  }
                />
              </>
            ) : (
              <div
                style={{
                  height: "40px",
                  width: "100%",
                }}
              >
                {i === 4 && (
                  <div
                    className={`divider ${
                      mode === "treso" ? "treso-divider" : "compta-divider"
                    }`}
                  ></div>
                )}
              </div>
            );
          })}
          <Settings />
        </div>
        <Toggle
          onClick={handleClickToggleSidebar}
          isClosed={isSidebarClosed}
          mode={mode}
        />
      </div>
    </div>
  );
};

export default Sidebar;
