import React from "react";
import useAuth from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, isVerified, isInitialized, isLogout, refreshToken } =
    useAuth();

  if (!isAuthenticated || isLogout) {
    return <Navigate to={"/login"}></Navigate>;
  }

  if (!isVerified) {
    return (window.location = "/verify-email");
  }

  return <>{children}</>;
};

export default AuthGuard;
