import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { useState } from "react";
import DashboardLayout from "../layouts/Dashboard";
import Register from "../pages/Auth/Register";
import {
  FolderList,
  Login,
  ForgetPassword,
  ResetPassword,
  ResendCode,
  NotFound,
  ProjectList,
  ProjectCreate,
  ProjectEdit,
  VerifyAccount,
  ProjectDelete,
  Graph,
} from "./elements";
import AuthGuard from "../layouts/Guards/AuthGuard";
import GuestGuard from "../layouts/Guards/GuestGuard";
import useAuth from "../hooks/useAuth";

// ----------------------------------------------------------------------

export default function Router() {
  const { isAuthenticated } = useAuth();

  const PATH_AFTER_LOGIN = isAuthenticated ? "/project" : "/login";

  return useRoutes([
    {
      path: "/",
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "register",
      element: (
        <GuestGuard>
          <Register />
        </GuestGuard>
      ),
    },
    {
      path: "forget-password",
      element: (
        <GuestGuard>
          <ForgetPassword />
        </GuestGuard>
      ),
    },
    {
      path: "reset-password/:token",
      element: (
        <GuestGuard>
          <ResetPassword />
        </GuestGuard>
      ),
    },
    {
      path: "resend-email",
      element: (
        <GuestGuard>
          <ResendCode />
        </GuestGuard>
      ),
    },
    {
      path: "verify-email",
      element: <VerifyAccount />,
    },
    {
      path: "/project",
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <ProjectList /> },
        {
          path: "new",
          element: <ProjectCreate />,
        },
        {
          path: ":id/edit",
          element: <ProjectEdit />,
        },
        {
          path: ":id/delete",
          element: <ProjectDelete />,
        },
        {
          path: ":id/graph",
          element: (
            <DashboardLayout>
              <Graph />
            </DashboardLayout>
          ),
        },
        {
          path: ":id",
          element: (
            <DashboardLayout>
              <FolderList />
            </DashboardLayout>
          ),
        },
        {
          path: ":id/listing",
          element: (
            <DashboardLayout>
              <FolderList />
            </DashboardLayout>
          ),
        },
        {
          path: ":id/graph",
          element: (
            <DashboardLayout>
              <Graph />
            </DashboardLayout>
          ),
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
}
