import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getOneProject } from "../../../data/slices/project";
import projectIcon from "./../../../assets/img/project.svg";
import useAuth from "../../../hooks/useAuth";
import user from "../../../assets/img/icons/user.svg";
import { PATH_HOME } from "../../../routes/paths";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import { useLocation } from "react-router-dom";
import { truncateWithThreeDots } from "../../../utilities/table";

const Settings = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentURL = location.pathname;
  const { project } = useSelector((state) => state.project);
  const [isHovered, setIsHovered] = useState(false);

  const { logout } = useAuth();
  const handleLogout = async () => {
    try {
      logout();
      dispatch({ type: "LOGOUT" });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    dispatch(getOneProject(id)).then((result) => {
      if (result.error) {
        displayValidationErrors(result?.error);
        navigate(PATH_HOME.project.root);
      }
    });
  }, [id]);

  return (
    <>
      <button
        key={project?.id}
        type="button"
        onClick={() => {
          navigate(PATH_HOME.project.root);
        }}
        className="button nav-link config-buttons"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img className="nav-img" src={projectIcon} />
        <span className="hidden-element">
          {isHovered
            ? project?.projectName
            : truncateWithThreeDots(project?.projectName, 24)}
        </span>
      </button>

      {!currentURL.includes("graph") && (
        <button
          key="logout"
          type="button"
          onClick={handleLogout}
          className="button nav-link config-buttons"
        >
          <img className="nav-img" src={user} />
          <span className="hidden-element log-out-element ">Déconnecter</span>
        </button>
      )}
    </>
  );
};

export default Settings;
