import React from "react";
import { Modal } from "antd";
import logo_respensive from "../../assets/img/logo_respensive.png";

const ModalComponent = ({
  id,
  open,
  headerContent,
  handleClose,
  data,
  content,
  ...rest
}) => {
  return (
    <>
      <Modal
        title={
          <div>
            <img alt="logo" src={logo_respensive} className="modal-logo" />
            {headerContent}
          </div>
        }
        centered
        open={open}
        onOk={() => handleClose(id)}
        onCancel={() => handleClose(id)}
        width={1030}
        height={789}
        className="modal-style"
        footer={null}
      >
        {content}
      </Modal>
    </>
  );
};

export default ModalComponent;
