import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categorytoEdit: "",
  popoverId: "",
  popoverIdTracker: "",
  expandedCategories: [],
  categoryToDelete: null,
  popoverFooterTracker: false,
};

const slice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setOpenPopover: (state, action) => {
      state.popoverId = action.payload;
    },
    setPopOverIdTracker: (state, action) => {
      state.popoverIdTracker = action.payload;
    },
    setOpenTableFooter: (state, action) => {
      state.popoverFooterTracker = action.payload;
    },
    expandCategories: (state, { payload }) => {
      if (payload.key) {
        const index = state.expandedCategories.indexOf(payload.key);

        if (index !== -1) {
          state.expandedCategories.splice(index, 1);
        } else {
          state.expandedCategories.push(payload.key);
        }
      }
    },
    openDeleteDialog: (state, { payload }) => {
      state.categoryToDelete = payload;
    },
  },
  extraReducers: {},
});

export const {
  expandCategories,
  setOpenPopover,
  setOpenTableFooter,
  openDeleteDialog,
  setPopOverIdTracker,
} = slice.actions;
export const reducer = slice.reducer;

export default slice;
