import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utilities/axios";
import useAuth from "../../hooks/useAuth";
import { setSession } from "../../contexts/JWTAuthContext";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
const initialState = {
  user: null,
  status: "idle",
  error: null,
};

export const resendEmail = createAsyncThunk(
  "user/resendEmail",
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/auth/verification/request`);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "user/forgetPassword",
  async (email, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/auth/password-reset/request`, email);
      data = await response?.data;
      if (response?.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error({ err });
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (values, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/auth/password-reset`, values);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.error(err);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const verifyAccount = createAsyncThunk(
  "user/verifyAccount",
  async (code, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/auth/verify-account`, code);
      data = await response.data;
      if (response.status === 200) {
        setSession(null);
        window.location.href = "/project/new";
      }
      throw new Error(response.statusText);
    } catch (err) {
      localStorage.clear();
      window.location.href = "/login";
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [resendEmail.pending]: (state, action) => {
      state.status = "loading";
    },
    [resendEmail.fullfiled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload;
    },
    [resendEmail.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [forgetPassword.pending]: (state, action) => {
      state.status = "loading";
    },
    [forgetPassword.fullfiled]: (state, { payload }) => {
      state.status = "succeeded";
    },
    [forgetPassword.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [resetPassword.pending]: (state, action) => {
      state.status = "loading";
    },
    [resetPassword.fullfiled]: (state, { payload }) => {
      state.status = "succeeded";
    },
    [resetPassword.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [verifyAccount.pending]: (state, action) => {
      state.status = "loading";
    },
    [verifyAccount.fullfiled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload;
    },
    [verifyAccount.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
