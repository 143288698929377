import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { handleMode } from "../../../data/slices/layout";
import Switch from "react-switch";
import coins from "./../../../assets/img/coins.svg";
import compta from "./../../../assets/img/compta.svg";
import IconMode from "../../../components/IconMode";
import { useSelector } from "react-redux";

function SwitchMode() {
  const { mode } = useSelector((state) => state.layout);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(mode === "compta");
  const handleModeChange = () => {
    setChecked(!checked);
    dispatch(handleMode(checked === true ? "treso" : "compta"));
  };

  return (
    <Switch
      checked={checked}
      onChange={handleModeChange}
      without-border={checked ? "treso" : "compta"}
      offHandleColor="#FFFFFF"
      onHandleColor="#FFFFFF"
      handleDiameter={45}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={30}
      width={110}
      className={`react-switch ${mode}`}
      checkedIcon={
        <IconMode
          bottomText="Compta"
          icon={compta}
          classNameParent="switch-group checked-icon"
          classNameContainer="comptability-container"
          classNameText="switch-text"
          classNameIcon="switch-icon"
        />
      }
      uncheckedIcon={
        <IconMode
          topText="Trésorerie"
          icon={coins}
          classNameParent="switch-group unchecked-icon"
          classNameContainer="tresory-container"
          classNameText="switch-text treso-text"
          classNameIcon="switch-icon tresory"
        />
      }
      checkedHandleIcon={
        <div className="switch-group unchecked-handle-icon">
          <img src={coins} alt="treso" />
        </div>
      }
      uncheckedHandleIcon={
        <div className="switch-group checked-handle-icon">
          <img src={compta} alt="compta" />
        </div>
      }
    />
  );
}

export default SwitchMode;
