import React from "react";
import CustomNavLink from "./CustomNavLink";
import CustomRangePicker from "../../../components/RangePicker";
import MenuPopover from "./MenuPopover";
import MenuButton from "./MenuButton";

const NavButton = ({
  id,
  route,
  onClick,
  name,
  icon,
  isActive,
  isNavButton,
  arrowIcon,
  label,
  hasDivider,
  openOverlay,
  isSubNav,
  background,
  isSwitch,
  isDatePicker,
  handleMonthChange,
  selectedMonthRange,
  subItems,
  disabled,
  isPopover,
  mode,
}) => {
  const helpButton = name === "Besoin d’aide ?";
  const modeButton = name === "Trésorerie";

  return isNavButton ? (
    <CustomNavLink
      key={id}
      route={route}
      name={name}
      label={label}
      openOverlay={openOverlay}
      arrowIcon={arrowIcon}
      icon={icon}
      isActive={isActive}
      onClick={onClick}
      background={background}
      isSwitch={isSwitch}
      subItems={subItems}
      isPopover={isPopover}
      modeButton={modeButton}
    />
  ) : isPopover ? (
    <MenuPopover
      isPopover={isPopover}
      isActive={isActive}
      isSubNav={isSubNav}
      icon={icon}
      label={label}
      background={background}
      name={name}
      subItems={subItems}
    />
  ) : isSubNav ? (
    <CustomNavLink
      name={name}
      label={label}
      icon={icon}
      isActive={isActive}
      route={route}
      onClick={onClick}
      background={background}
      isSubNav={isSubNav}
      className=" button nav-link"
      isSwitch={isSwitch}
    />
  ) : isDatePicker ? (
    <>
      <CustomRangePicker
        selectedMonthRange={selectedMonthRange}
        handleMonthChange={handleMonthChange}
        className={"button nav-link custom-range-picker"}
        hasDivider={hasDivider}
        label="Période Affichée"
      />
      {hasDivider && (
        <div
          className={`
          divider ${mode === "treso" ? " treso-divider" : "compta-divider"} `}
        ></div>
      )}
    </>
  ) : (
    <>
      <MenuButton
        key={id}
        name={name}
        handleSubmit={(e) => {
          onClick(name);
          e.preventDefault();
        }}
        className={
          helpButton
            ? "button nav-link config-buttons "
            : `button nav-link ${disabled ? " disabled" : ""}`
        }
        primaryIcon={icon}
        secondaryIcon={arrowIcon}
      />
      {hasDivider && (
        <div
          className={`divider ${
            mode === "treso" ? "treso-divider" : "compta-divider"
          }`}
        ></div>
      )}
    </>
  );
};
export default NavButton;
