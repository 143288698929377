import { useEffect } from "react";

function useClickOutside(refs, closeFunc) {
  useEffect(() => {
    let handler = (e) => {
      let isOutside = true;

      for (const ref of refs) {
        if (ref.current && ref.current.contains(e.target)) {
          isOutside = false;
          break;
        }
      }

      if (isOutside) {
        closeFunc();
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, [refs, closeFunc]);
}

export default useClickOutside;
