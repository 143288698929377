import React, { useEffect } from "react";
import NProgress from "nprogress";
import { Spin } from "antd";

const LoadingScreen = () => {
  // useEffect(() => {
  //   NProgress.start();

  //   return () => {
  //     NProgress.done();
  //   };
  // }, []);
  return <Spin fullscreen />;
};

export default LoadingScreen;
