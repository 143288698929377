import { useSelector } from "react-redux";
import { convertToYYYYMM } from "../utilities/filters";
import isEqual from "lodash/isEqual";

export const useFiltersAndDates = () => {
  const { mode, selectedMonths, selectedMonthsSynthesis } = useSelector(
    (state) => state.layout
  );
  const {
    footerFiltersStatus,
    footerFiltersTypes,
    filtersValues: originalFiltersValues,
  } = useSelector((state) => state.categories);

  const constructFilters = () => {
    const filtersToUse =
      mode === "treso" ? footerFiltersStatus : footerFiltersTypes;

    let updatedFiltersValues = { ...originalFiltersValues };

    const hasNonEmptyType = updatedFiltersValues.type.length !== 0;
    const hasNonEmptyStatus = updatedFiltersValues.status.length !== 0;
    const hasSimulation = updatedFiltersValues.type.includes("simulation");
    const hasEngageOrPointe =
      updatedFiltersValues.status.includes("engagé") ||
      updatedFiltersValues.status.includes("pointé");
    const hasNoPrev = !updatedFiltersValues.status.includes("prévisionnel");

    if (
      hasNonEmptyType &&
      hasNonEmptyStatus &&
      hasSimulation &&
      hasEngageOrPointe &&
      hasNoPrev
    ) {
      updatedFiltersValues = {
        ...updatedFiltersValues,
        status: [...updatedFiltersValues.status, "prévisionnel"],
      };
    }

    if (hasEngageOrPointe || !hasNoPrev) {
      updatedFiltersValues = {
        ...updatedFiltersValues,
        type: [...updatedFiltersValues.type, "réel"],
      };
    }

    const footerConstructedFilters =
      hasNonEmptyType || hasNonEmptyStatus
        ? mode === "treso"
          ? {
              ...(hasNonEmptyStatus && {
                status: updatedFiltersValues.status.join(","),
              }),
              ...(hasNonEmptyType && {
                type: updatedFiltersValues.type.join(","),
              }),
            }
          : {
              ...(hasNonEmptyType && {
                type: updatedFiltersValues.type.join(","),
              }),
            }
        : filtersToUse;

    const nonEmptyFilter = hasNonEmptyType || hasNonEmptyStatus;

    const tableConstructedFilters =
      nonEmptyFilter && mode === "treso"
        ? {
            ...(hasNonEmptyStatus
              ? { status: updatedFiltersValues.status.join(",") }
              : {}),
            ...(hasNonEmptyType
              ? { type: updatedFiltersValues.type.join(",") }
              : {}),
          }
        : {
            ...(hasNonEmptyType && {
              type: updatedFiltersValues.type.join(","),
            }),
          };
    return { footerConstructedFilters, tableConstructedFilters };
  };

  const getStartAndEndDate = () => {
    const startDate = convertToYYYYMM(selectedMonths[0]);
    const endDate = convertToYYYYMM(selectedMonths[selectedMonths.length - 1]);

    return { startDate, endDate };
  };

  const getStartAndEndDateSynthesis = () => {
    const startDateSynthesis = convertToYYYYMM(selectedMonthsSynthesis[0]);
    const endDateSynthesis = convertToYYYYMM(
      selectedMonthsSynthesis[selectedMonthsSynthesis.length - 1]
    );

    return { startDateSynthesis, endDateSynthesis };
  };
  return { constructFilters, getStartAndEndDate, getStartAndEndDateSynthesis };
};
