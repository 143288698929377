import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Select, TreeSelect, message } from "antd";
import { Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import dayjs from "dayjs";
import localeFr from "antd/es/date-picker/locale/fr_FR";
import Button from "../../Button";
import CustomInput from "../../Input";
import { displayValidationErrors } from "../../../utilities/noticeDisplayer";
import calendarInput from "../../../assets/img/calendarInput.svg";
import { WRITING_TYPES, TRESORY_STATUS } from "../../../data/constants";
import { getCategories } from "../../../data/slices/categories";
import {
  createElements,
  editElement,
  getElementsWithSum,
} from "../../../data/slices/elements";
import { closeModal } from "../../../data/slices/modals";
import formattedCategories from "../../../utilities/formattedCategories";
import useIsMountedRef from "../../../hooks/useIsMountedRef";
import { useFiltersAndDates } from "../../../hooks/useFiltersAndDate";
import { findById } from "../../../utilities/table";
import { convertToYYYYMMDD } from "../../../utilities/filters";
import { useLocation } from "react-router-dom";

const { Option } = Select;

const AddWritingForm = ({
  onCancel,
  edit,
  element,
  selectedCategory,
  projectId,
  isAction,
  date,
  type,
}) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const isMounted = useIsMountedRef();
  const location = useLocation();
  const [submitButtonState, setSubmitButtonState] = useState(null);
  const { constructFilters, getStartAndEndDate, getStartAndEndDateSynthesis } =
    useFiltersAndDates();

  const { footerConstructedFilters, tableConstructedFilters } =
    constructFilters();
  const { startDate, endDate } = getStartAndEndDate();
  const { startDateSynthesis, endDateSynthesis } =
    getStartAndEndDateSynthesis();

  const { mode } = useSelector((state) => state.layout);
  const { reellePopup } = useSelector((state) => state.elements);
  const { categories, categoriesForSelect } = useSelector(
    (state) => state.categories
  );
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  }, [inputRef]);

  const { expandedCategories } = useSelector((state) => state.table);

  const currentCategory = reellePopup?.category
    ? reellePopup?.category
    : reellePopup;

  const [isSpecial, setIsSpecial] = useState(currentCategory?.isSpecial);
  const categoriesForSelectToUse = currentCategory
    ? categoriesForSelect.filter(
        (el) => currentCategory?.isSpecial === el?.isSpecial
      )
    : isAction && type === "simulation"
    ? categoriesForSelect.filter((el) => el?.isSpecial !== 1)
    : categoriesForSelect;

  const categoryId = currentCategory?.id;
  const finalCategories = formattedCategories(categoriesForSelectToUse);

  useEffect(() => {
    if (!categories && !edit) {
      dispatch(getCategories({ projectId: projectId, mode: mode }));
    }
  }, [projectId, mode]);

  const WritingSchema = Yup.object().shape({
    designation: Yup.string().required("Libelle est obligatoire"),
    amount: Yup.string().required("Montant est obligatoire"),
    categoryId: Yup.string().required("Catégorie est obligatoire"),
    type: Yup.string().required("Type est obligatoire"),
    status: Yup.string().required("Statut est obligatoire"),
  });
  const customDate = dayjs(convertToYYYYMMDD(date));
  const currentDate = dayjs();
  const sameMonthAndYear =
    customDate.month() === currentDate.month() &&
    customDate.year() === currentDate.year();

  const initialValues = {
    designation: edit ? element.designation : null,
    amount: edit ? element.amount : null,
    categoryId: currentCategory ? currentCategory?.id : null,
    dateCompta:
      edit && element?.dateCompta !== null
        ? dayjs(element.dateCompta).hour(1).toISOString().split("T")[0]
        : (mode === "compta" &&
            (date && !sameMonthAndYear
              ? dayjs(convertToYYYYMMDD(date))
                  .hour(1)
                  .toISOString()
                  .split("T")[0]
              : !isAction && dayjs(new Date()).toISOString().split("T")[0])) ||
          null,
    dateTreso:
      edit && element?.dateTreso !== null
        ? dayjs(element?.dateTreso).hour(1).toISOString().split("T")[0]
        : (mode === "treso" &&
            (date && !sameMonthAndYear
              ? dayjs(convertToYYYYMMDD(date))
                  .hour(1)
                  .toISOString()
                  .split("T")[0]
              : isAction && dayjs(new Date()).toISOString().split("T")[0])) ||
          null,
    type: type === "simulation" ? "simulation" : edit ? element.type : "réel",
    status: edit
      ? element.status
      : mode === "compta" && isSpecial
      ? "pointé"
      : "prévisionnel",
  };

  const handleSubmitButtonState = (isSubmit) => {
    setSubmitButtonState(isSubmit);
  };

  const onSubmit = async (values, { setSubmitting, resetForm, errors }) => {
    try {
      if (edit) {
        const amount = values?.amount;
        let dataState = {
          designation: values.designation,
          amount: amount.toString(),
          dateCompta: values.dateCompta,
          dateTreso: values.dateTreso,
          type: values.type,
          status: values.status,
        };
        if (selectedCategory?.id !== values.category) {
          dataState = {
            designation: values.designation,
            categoryId: Number(values.categoryId),
            amount: amount.toString(),
            dateCompta: values.dateCompta,
            dateTreso: values.dateTreso,
            type: values.type,
            status: values.status,
          };
        }

        const result = await dispatch(
          editElement({
            elementId: element?.id,
            values: dataState,
            project: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...tableConstructedFilters },
            },
            isListing: location.pathname.includes("listing"),
            listing: {
              params: { ...tableConstructedFilters, startDate, endDate },
              mode,
              projectId,
            },
            sum: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...footerConstructedFilters },
            },
            synthesis: {
              mode,
              projectId,
              params: {
                startDate: startDateSynthesis,
                endDate: endDateSynthesis,
                ...tableConstructedFilters,
              },
            },
          })
        );
        setSubmitting(false);
        resetForm();
        if (editElement.rejected.match(result)) {
          if (isMounted.current) {
            setSubmitting(false);
          }
          displayValidationErrors(
            result?.errors ? result.errors : result?.payload
          );
        }
        if (editElement.fulfilled.match(result)) {
          message.success("Écriture à été modifiée avec succès");

          if (submitButtonState === "validate") {
            dispatch(closeModal("add-writing-expression"));
          }
        }
      } else {
        const categoryToSend = findById(values.categoryId, categoriesForSelect);

        const datesToSend =
          categoryToSend.isSpecial === 0
            ? {
                dateCompta: values.dateCompta,
                dateTreso: values.dateTreso,
              }
            : mode === "compta"
            ? { dateCompta: values.dateCompta, dateTreso: null }
            : { dateTreso: values.dateTreso, dateCompta: null };
        const result = await dispatch(
          createElements({
            categoryId: categoryId ?? values.categoryId,
            values: {
              ...values,
              designation: values.designation,
              amount: values.amount,
              ...datesToSend,
              type: values.type,
              status: values.status,
            },
            expandedCategories,
            project: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...tableConstructedFilters },
            },
            isListing: location.pathname.includes("listing"),
            listing: {
              params: { ...tableConstructedFilters, startDate, endDate },
              mode,
              projectId,
            },
            sum: {
              mode,
              projectId: projectId,
              params: { startDate, endDate, ...footerConstructedFilters },
            },
            synthesis: {
              mode,
              projectId: projectId,
              params: {
                startDate: startDateSynthesis,
                endDate: endDateSynthesis,
                ...tableConstructedFilters,
              },
            },
          })
        );
        setSubmitting(false);
        if (createElements.rejected.match(result)) {
          if (isMounted.current) {
            setSubmitting(false);
          }
          displayValidationErrors(
            result?.errors ? result.errors : result.payload
          );
        }
        if (createElements.fulfilled.match(result)) {
          message.success("Écriture à été ajoutée avec succès");

          if (submitButtonState === "validate") {
            dispatch(closeModal("add-writing-expression"));
          }
          resetForm();
        }
      }
    } catch (error) {
      setSubmitting(false);
      displayValidationErrors(error);
    }
  };

  return (
    <>
      <p className="description">* champs obligatoires.</p>
      <p className="description">
        Veuillez renseigner les informations pour votre projet
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={WritingSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleBlur,
          resetForm,
          setFieldValue,
          errors,
        }) => (
          <Form className="form-container" autoComplete="off" preserve={false}>
            <Field
              component={CustomInput}
              value={values.designation}
              label="Libellé*"
              name="designation"
              type="text"
              className={
                values.designation
                  ? "input-wrapper-writing form-element-input small"
                  : " input-wrapper-writing form-element-input not-filled"
              }
              placeholder="Mon projet beta"
              onBlur={handleBlur}
              onChange={handleChange}
              innerRef={inputRef}
            />
            <Field
              component={CustomInput}
              value={values.amount}
              label="Montant*"
              name="amount"
              type="string"
              className={
                values.amount
                  ? "input-wrapper-writing form-element-input small"
                  : " input-wrapper-writing form-element-input not-filled"
              }
              placeholder="Mon projet beta"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <div className="wrapper-select">
              <label htmlFor="type" className="input-text">
                Catégorie*
              </label>
              <TreeSelect
                showSearch
                disabled={!edit && categoryId && !isAction}
                bordered={false}
                filterTreeNode={(input, treeNode) =>
                  treeNode.title.toLowerCase().includes(input.toLowerCase())
                }
                name="category"
                value={values.categoryId}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Choisir une catégorie"
                allowClear
                treeDefaultExpandAll
                className={
                  values.categoryId
                    ? "form-element-input small"
                    : "form-element-input not-filled"
                }
                onBlur={handleBlur}
                onChange={(value) => {
                  setFieldValue("categoryId", value);
                  const categoryToSend = findById(value, categoriesForSelect);
                  setIsSpecial(categoryToSend.isSpecial);
                }}
                treeData={finalCategories}
                style={{
                  width: "100%",
                  borderColor: "#000",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderRadius: "30px",
                  display: "flex",
                }}
              />
            </div>
            <div className="element-wrapper">
              {((mode === "treso" && isSpecial) || !isSpecial) && (
                <div className="wrapper-select">
                  <label htmlFor="Date de comptabilité*" className="input-text">
                    Date de trésorerie*
                  </label>
                  <DatePicker
                    style={{ width: "100%" }}
                    id="dateTreso"
                    name="dateTreso"
                    defaultValue={
                      values.dateTreso ? dayjs(values.dateTreso) : undefined
                    }
                    placeholder="Choisir une date"
                    locale={localeFr}
                    className={
                      values.dateTreso
                        ? "form-element-input small"
                        : "form-element-input calendar not-filled"
                    }
                    onBlur={handleBlur}
                    format="DD/MM/YYYY"
                    onChange={(dateString) => {
                      setFieldValue(
                        "dateTreso",
                        dateString
                          ? dayjs(dateString)
                              .hour(1)
                              .toISOString()
                              .split("T")[0]
                          : null
                      );
                    }}
                    suffixIcon={<img src={calendarInput} alt="CalendarIcon" />}
                    size="large"
                    dropdownClassName="custom-datepicker-dropdown"
                  />
                </div>
              )}
              {((mode === "compta" && isSpecial) || !isSpecial) && (
                <div className="wrapper-select">
                  <label htmlFor="Date de comptabilité*" className="input-text">
                    Date de comptabilité*
                  </label>
                  <DatePicker
                    id="dateCompta"
                    name="dateCompta"
                    defaultValue={
                      values.dateCompta ? dayjs(values.dateCompta) : undefined
                    }
                    format="DD/MM/YYYY"
                    placeholder="Choisir une date"
                    locale={localeFr}
                    className={
                      values.dateCompta
                        ? "form-element-input small"
                        : "form-element-input calendar not-filled"
                    }
                    dropdownClassName="custom-datepicker-dropdown"
                    suffixIcon={<img src={calendarInput} alt="CalendarIcon" />}
                    size="large"
                    onChange={(dateString) => {
                      setFieldValue(
                        "dateCompta",
                        dateString
                          ? dayjs(dateString)
                              .hour(1)
                              .toISOString()
                              .split("T")[0]
                          : null
                      );
                    }}
                  />
                </div>
              )}
            </div>

            <div className="element-wrapper">
              {((mode === "treso" && isSpecial) || !isSpecial) && (
                <div className="wrapper-select">
                  <label htmlFor="status" className="input-text">
                    Statut de trésorerie*
                  </label>
                  <Select
                    name="status"
                    value={values?.status}
                    disabled={
                      type === "simulation" || (mode === "compta" && isSpecial)
                    }
                    size="large"
                    bordered={false}
                    className={
                      values.status
                        ? "form-element-input small"
                        : "form-element-input calendar not-filled"
                    }
                    style={{
                      width: "100%",
                      borderColor: "#000",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderRadius: "30px",
                      display: "flex",
                    }}
                    onChange={(value) => {
                      setFieldValue("status", value);
                    }}
                    onBlur={handleBlur}
                  >
                    {TRESORY_STATUS?.map((option) => (
                      <Option
                        key={option.key}
                        value={option.value}
                        className="option"
                      >
                        <div
                          className="img-container element-status "
                          style={{
                            background: option.backgroundColor,
                          }}
                        >
                          <img src={option.icon} alt="icon" />
                          <div>{option.label}</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              {((mode === "compta" && isSpecial) || !isSpecial) && (
                <div className="wrapper-select">
                  <label htmlFor="type" className="input-text">
                    Type d'écriture*
                  </label>
                  <Select
                    name="type"
                    value={values?.type}
                    disabled={!edit && isAction}
                    size="large"
                    bordered={false}
                    className={
                      values.type
                        ? "form-element-input small"
                        : "form-element-input calendar not-filled"
                    }
                    style={{
                      width: "100%",
                      borderColor: "#000",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderRadius: "30px",
                      display: "flex",
                    }}
                    onChange={(value) => {
                      setFieldValue("type", value);
                    }}
                  >
                    {WRITING_TYPES?.map((option) => (
                      <Option
                        key={option.key}
                        value={option.value}
                        disabled={option.value === "soldé"}
                        className="option"
                      >
                        <div
                          className="img-container element-status"
                          style={{ background: option.backgroundColor }}
                        >
                          <img src={option.icon} alt="icon" />
                          <div>{option.label}</div>
                        </div>
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
            </div>

            <div className="submit-button-wrapper">
              <Button
                text={isSubmitting ? "envoi en cours" : "Valider"}
                type="submit"
                disabled={isSubmitting}
                color="primary"
                className="submit-button"
                allWidth
                onClick={() => handleSubmitButtonState("validate")}
              />
              <Button
                text="Annuler"
                color="primary"
                className="reset-button"
                allWidth
                onClick={(e) => {
                  onCancel();
                  e.preventDefault();
                }}
              />
            </div>
            <div className="validate">
              <Button
                type="submit"
                text={
                  isSubmitting
                    ? "envoi en cours"
                    : "Valider et ajouter une nouvelle écriture"
                }
                disabled={isSubmitting}
                color="primary"
                className="validate-button"
                allWidth
                onClick={() => handleSubmitButtonState("submit")}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddWritingForm;
