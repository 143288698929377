import React from "react";
import check from "../../assets/img/icons/check.png";
import Button from "../Button";

export const NeedHelpHeader = () => {
  return (
    <div className="modal-header">
      <h1>Bienvenue</h1>
      <p>Que diriez-vous d’une petite visite guidée de l’application ?</p>
    </div>
  );
};

export const NeedHelpContent = () => {
  const instructionsList = [
    { text: "Vidéo d’1 minute sur les grands principes.", icon: check },
    {
      text: "Vous pourrez retrouver la vidéo et bien d’autres dans la section aideà tout moment.",
      icon: check,
    },
    {
      text: `Pas expert en gestion comptable ? pas de problème, consultez également nos articles “métier” pour vous accompagner.`,
      icon: check,
    },
  ];

  return (
    <div>
      <div className="modal-content">
        <div className="instructions">
          {instructionsList.map((item, index) => (
            <ul key={index}>
              <li>
                <img src={item.icon} /> {item.text}
              </li>
            </ul>
          ))}
        </div>
        <div className="video">
          <iframe
            title="Example Video"
            width="638px"
            height="382px"
            src="https://www.youtube.com/embed/your-video-id"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <div className="buttons-wrapper">
        <Button text="Passer" />
        <Button text="Centre d’aide" />
      </div>
    </div>
  );
};
