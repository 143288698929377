import React from "react";


const MenuButton = ({
  id,
  handleSubmit,
  name,
  className,
  primaryIcon,
  secondaryIcon,
}) => { 
  return (
      <button
        key={id}
        type="button"
        onClick={handleSubmit}
        className={className}
      >
        <img className="nav-img" src={primaryIcon} />
        <span className="hidden-element">{name}</span>
        <img className="nav-img" src={secondaryIcon} id="arrow" />
      </button>
  );
};
export default MenuButton;
