import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { NeedHelpModal, AddWritingModal } from "../Modals";
import { closeModal } from "../../data/slices/modals";
import {
  NeedHelpHeader,
  NeedHelpContent,
} from "../ModalsProvider/NeedHelpContent";

const ModalsProvider = () => {
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };

  return (
    <>
      <NeedHelpModal
        id="modal-need-help"
        open={modalState("modal-need-help", "open")}
        data={modalState("modal-need-help", "data")}
        handleClose={handleClose}
        headerContent={<NeedHelpHeader />}
        content={<NeedHelpContent />}
      />
      <AddWritingModal
        id="add-writing-expression"
        open={modalState('add-writing-expression', "open")}
        data={modalState('add-writing-expression', "data")}
        handleClose={handleClose}
      />
    </>
  );
};

export default ModalsProvider;
